<template>
  <div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="localInterval"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        :disableIntervalButtons="true"
        @intervalChanged="intervalChanged"
      />
    </div>
    <ejs-chart
      :style="IsDashboard ? '' : 'margin-top:65px'"
      :id="'medicine-weekly-chart-component' + Id"
      :primaryXAxis="xAxis"
      :primaryYAxis="yAxis"
      :palettes="palettes"
      :tooltip="IsDashboard ? {} : tooltip"
      background="transparent"
      width="100%"
      :title="IsDashboard ? $t('module.charts.' + Chart.Name) : ''"
      :titleStyle="IsDashboard ? titleStyle : {}"
      :height="IsDashboard ? '185px' : '90%'"
      isResponsive="true"
    >
      <e-series-collection>
        <e-series
          :dataSource="chartData"
          type="StackingColumn100"
          xName="date"
          yName="complete"
          fill="var(--eh-secondary-5)"
          :name="$t('module.charts.collectedMedicines')"
        ></e-series>
        <e-series
          :dataSource="chartData"
          type="StackingColumn100"
          xName="date"
          yName="uncomplete"
          fill="#668293"
          :name="$t('module.charts.missedMedicines')"
        ></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import {
  ChartPlugin,
  DateTime,
  Tooltip,
  StackingColumnSeries,
  Category,
} from "@syncfusion/ej2-vue-charts";
import { ChartUiLogic } from "@/Logic/Ui/chart-ui";
import { ChartBackendLogic } from "../../../../../Logic/Backend/chart";
import IntervalManager from "../Components/IntervalManager.vue";

Vue.use(ChartPlugin);

export default {
  components: { IntervalManager },
  name: "ExerciseCalories",
  data() {
    return {
      chartData: [],
      currentDate: moment(),
      currentDateText: null,
      localInterval: "Week",
      localChartType: null,
      //Chart paraméterek
      xAxis: {
        title: this.IsDashboard ? null : this.$t("base.calendar.date"),
        valueType: "DateTime",
        interval: this.IsDashboard ? 5 : null,
        intervalType: "Days",
        labelFormat: "MM-dd",
        labelIntersectAction: "Rotate45",
      },
      yAxis: {
        rangePadding: "None",
        interval: 20,
      },
      palettes: ["var(--eh-secondary-5)"],
      marker: {
        visible: true,
      },
      tooltip: {
        enable: true,
        format: "${point.x} : <b>${point.y} (${point.percentage}%)</b>",
      },
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--eh-secondary-5)",
      },
    };
  },
  props: {
    Interval: String,
    ChartType: String,
    PatientId: Number,
    IsDashboard: Boolean,
    Chart: Object,
    Id: [String, Number],
  },
  provide: {
    chart: [StackingColumnSeries, Category, DateTime, Tooltip],
  },
  methods: {
    //intervallum beállítás
    setInterval() {
      //intervallum objektum generálás megadott intervllum és dátum alapján
      const interval = ChartUiLogic.IntervalManager.setInterval(
        this.localInterval,
        this.xAxis,
        this.currentDate
      );
      //kapott aktuális intervallum beállítások tárolása
      this.currentDate = interval.currentDate;
      this.currentDateText = interval.currentDateString;
      this.xAxis = interval.xAxis;
      //üres mezők feltöltése
      this.fillEmptyDays();
    },
    //intervallum változás
    async intervalChanged(currentDate, interval) {
      //aktuális dátum frissítés
      this.currentDate = currentDate;
      //aktuális intervallum frissítés
      this.localInterval = interval;
      //az intervallum napi?
      if (interval == this.$enums.ChartInterval.Day.Value) {
        //igen, a chart típus tárolása lokálisan
        this.localChartType = this.$enums.DiagramType.diary;
      } else {
        //nem, kapott chart típus tárolása lokálisan
        this.localChartType = this.ChartType;
      }
      //adatok frissítése
      await this.getData();
      //intervallum beállítása
      this.setInterval();
    },
    //adatok kérése
    async getData() {
      //chart adatok betöltése
      await this.getChartData();
    },
    //chart adatok kérés
    async getChartData() {
      //kérés válasz tároló
      var responseChartData = null;
      //van beteg id?
      if (this.PatientId) {
        //igen, beteghez tartozó chart adatok kérése
        responseChartData = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
      } else {
        //nem, saját chart adatok kérése
        responseChartData = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        //chart adatok konvertált formában tárolása
        this.chartData = this.convertChartData(responseChartData);
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //adat konvertálás
    convertChartData(data) {
      //az adatok lista nem nulla elemű?
      if (data.length > 0) {
        //nem, segéd változó használata
        var temp = [];
        //minden adat átformázott alakja tárolás a segédben
        data.forEach((x) => {
          temp.push({
            date: x[this.Chart.DataProperties.date],
            uncomplete:
              x[this.Chart.DataProperties.sum] -
              x[this.Chart.DataProperties.taken],
            complete: x[this.Chart.DataProperties.taken],
          });
        });
        //visza adjuk a konvertált adatokat
        return temp;
      } else {
        //igen, vissza adunk egy elemű listát, intervallumon kívüli értékkel
        return [
          {
            date: moment(this.xAxis.maximum)
              .endOf("weeks")
              .add(2, "days"),
            uncomplete: 1,
            complete: 0,
          },
        ];
      }
    },
    //chart intervallum paraméterek megadása
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.localInterval,
        this.currentDate
      );
    },
    //üres mező feltöltése
    fillEmptyDays() {
      var lastElement = this.chartData[this.chartData.length - 1];
      if (lastElement) {
        this.chartData.push({
          date: moment(lastElement.date)
            .add(
              1,
              this.interval == this.$enums.ChartInterval.Day.Value
                ? "hours"
                : "days"
            )
            .format(),
          uncomplete: 0,
          complete: 0,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //adatok betöltése
    await this.getData();
    //intervallum beállítása
    this.setInterval();
  },
};
</script>

<style scoped>
@import '../style.css';
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--eh-secondary-5);
  font-size: 23px;
}
</style>
